import React, {
  ReactNode,
  createContext,
  useContext,
  useReducer,
  useRef,
} from 'react';
import _ from 'lodash';
import { Language, ReviewsInfo, Template, ReviewData } from '@common/types';
import { StarsRatingBarProps } from '@wix/design-system/dist/types/StarsRatingBar';

interface TemplateContextProviderProps {
  children?: ReactNode;
  template: Template;
  language: string;
  languages?: Array<Language>;
  reviewsInfo: ReviewsInfo;
  purchaseCount: number;
  isMobileUserAgent?: boolean;
  entry?: string;
  error?: string;
  isEligibleToGiveReview?: boolean;
  userAlreadyLeftReview?: boolean;
  userReviewForTemplate: ReviewData | null;
}

type State = {
  template: Template;
  reviewsInfo: ReviewsInfo;
  purchaseCount: number;
  language: string;
  languages?: Array<Language>;
  isMobileUserAgent?: boolean;
  showStickyCTAs: boolean;
  entry?: string;
  error?: string;
  isEligibleToGiveReview?: boolean;
  userAlreadyLeftReview?: boolean;
  userReviewForTemplate: ReviewData | null;
  loadNewReview?: boolean;
  reviewModalAutoFocus?: boolean;
  reviewScore: StarsRatingBarProps['value'];
};

interface TemplateContextType extends State {
  dispatch: (arg: any) => void;
  navigationRefs: React.MutableRefObject<any>;
}

const TemplateContext = createContext<TemplateContextType | null>(null);

function mainReducer(state: State, action: any) {
  switch (action.type) {
    case ACTIONS.SHOW_STICKY_CTAS: {
      return {
        ...state,
        showStickyCTAs: action.show,
      };
    }
    case ACTIONS.SET_REVIEW_SCORE: {
      return {
        ...state,
        reviewScore: action.score,
      };
    }
    case ACTIONS.UPDATE_REVIEWS: {
      return {
        ...state,
        reviews: action.reviews,
      };
    }
    case ACTIONS.UPDATE_REVIEW: {
      const updatedReview = action.payload;
      const updatedReviews = state.reviewsInfo.reviews.map((review) => {
        if (review.id === updatedReview.id) {
          return updatedReview;
        }

        return review;
      });

      const newState: State = {
        ...state,
        reviewsInfo: { ...state.reviewsInfo, reviews: updatedReviews },
      };

      return newState;
    }
    case ACTIONS.DELETE_REVIEW: {
      const deletedReview = action.payload;
      const updatedReviews = state.reviewsInfo.reviews.filter(
        (review) => review.id !== deletedReview.id,
      );

      const newState: State = {
        ...state,
        reviewsInfo: { ...state.reviewsInfo, reviews: updatedReviews },
        userAlreadyLeftReview: false,
        userReviewForTemplate: null,
        reviewScore: 0,
      };

      return newState;
    }
    case ACTIONS.LOAD_NEW_REVIEW: {
      return {
        ...state,
        loadNewReview: action.loadNewReview,
      };
    }
    case ACTIONS.REVIEW_MODAL_AUTO_FOCUS: {
      return {
        ...state,
        reviewModalAutoFocus: action.reviewModalAutoFocus,
      };
    }
    case ACTIONS.ADD_REVIEW: {
      const { review } = action;
      const { average, total, reviews } = state.reviewsInfo;
      return {
        ...state,
        loadNewReview: false,
        userAlreadyLeftReview: true,
        userReviewForTemplate: review,
        reviewsInfo: {
          ...state.reviewsInfo,
          average: (average * total + review.overallScore) / (total + 1),
          total: total + 1,
          reviews: [review, ...reviews],
        },
      };
    }

    default:
      throw new Error();
  }
}

export const ACTIONS = {
  SHOW_STICKY_CTAS: 'SHOW_STICKY_CTAS',
  SET_REVIEW_SCORE: 'SET_REVIEW_SCORE',
  UPDATE_REVIEWS: 'UPDATE_REVIEWS',
  UPDATE_REVIEW: 'UPDATE_REVIEW',
  DELETE_REVIEW: 'DELETE_REVIEW',
  ADD_REVIEW: 'ADD_REVIEW',
  LOAD_NEW_REVIEW: 'LOAD_NEW_REVIEW',
  REVIEW_MODAL_AUTO_FOCUS: 'REVIEW_MODAL_AUTO_FOCUS',
};

export function TemplateContextProvider(props: TemplateContextProviderProps) {
  const navigationRefs = useRef<any>({});

  const [state, dispatch] = useReducer(mainReducer, {
    template: props.template,
    reviewsInfo: props.reviewsInfo,
    language: props.language,
    languages: props.languages,
    isMobileUserAgent: props.isMobileUserAgent,
    purchaseCount: props.purchaseCount ?? 0,
    showStickyCTAs: false,
    entry: props.entry,
    error: props.error,
    isEligibleToGiveReview: props.isEligibleToGiveReview,
    userAlreadyLeftReview: props.userAlreadyLeftReview,
    userReviewForTemplate: props.userReviewForTemplate,
    loadNewReview: false,
    reviewModalAutoFocus: false,
    reviewScore: 0,
  });

  return (
    <TemplateContext.Provider
      value={{
        ...state,
        dispatch,
        navigationRefs,
      }}
    >
      {props.children}
    </TemplateContext.Provider>
  );
}

export function useTemplateContext() {
  const appContext = useContext(TemplateContext);
  if (!appContext) {
    throw new Error(
      'useTemplateContext must be used within the TemplateContext.Provider',
    );
  }
  return appContext;
}
